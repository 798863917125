body {
  background-color: #1e1e1e;
  color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  padding: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

h1, h2 {
  color: #00ff00;
  margin: 0;
}

.command-line {
  margin: 10px 0;
}

.command-line span {
  color: #e4cb9e;
}

.command-line::before {
  content: '$ ';
  color: #e4cb9e;
  font-weight: bold;
}

a {
  color: #5dbafe;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  filter: sepia(100%);
}

.blinking-cursor {
  display: inline-block;
}

.blinking-cursor::after {
  content: '|';
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.progress-bar {
  width: 100%;
  background-color: #333;
  border: 1px solid #00ff00;
  margin: 5px 0;
  height: 20px;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #00ff00;
  
}
